.customer-table__container {
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 70vh !important;
  margin-top: 2%;

  h1 {
    font-size: 1.75em;
    font-weight: 400;
  }
}

.customer-table__header {
  align-items: center;
  background-color: #3A4456 ;
  border-radius: 5px 5px 0 0;
  color: white;
  display: flex;
  justify-content: space-between;
  min-height: 10%;
  padding: 1% 1%;
  width: 100%;
}

.customer-table__auto-sizer {
  height: 90%;
  width: 100%;
}


.customer-table__filter-dropdown {
  margin: 0 !important;
  width: 20%;
}

/* React Virtualized Table Theme */

.ReactVirtualized__Collection {
}

.ReactVirtualized__Collection__innerScrollContainer {
}

/* Grid default theme */

.ReactVirtualized__Grid {
  &:focus {
    outline: none;
  }
}

.ReactVirtualized__Grid__innerScrollContainer {
}

/* Table default theme */

.ReactVirtualized__Table {
}

.ReactVirtualized__Table__Grid {
}

.ReactVirtualized__Table__headerRow {
  box-shadow: -5px 10px 13px -6px rgba(0, 0, 0, .05);
  color: rgba(0, 0, 0, .5);
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

}

.ReactVirtualized__Table__row {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  color: rgba(0, 0, 0, .8);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  
  &:hover {
    color: rgba(0, 0, 0, 1);
  }
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;

  // make header text not highlightable
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: none;
  }
}

.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}
.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.ReactVirtualized__Table__sortableHeaderIcon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 24px;
          flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}