h1 {
  font-family: 'Roboto Condensed';
  font-size: 1.9vw;
  // font-weight: 500;
  margin: 0;
}

h2 {
  font-family: 'Roboto Condensed';
  font-size: 1.2vw;
  // font-weight: 700;
  margin: 0;
}

h3 {
  align-self: center;
  font-family: 'Open Sans' !important;
  font-size: 1.1vw !important;
  margin: 0 .5vw 0 .5vw !important;
  opacity: 0.7;
}

h4 {
  align-self: center;
  font-family: 'Open Sans' !important;
  margin: 0 1vw 0 .5vw !important;
  opacity: 0.5;
}

h5 {
  align-self: center;
  font-family: 'Open Sans' !important;
  font-size: 0.8vw !important;
  margin: 0 1vw 0 .5vw !important;
}

.dashboard__container {
  padding: 150px 5% 5% 5%;
}

.dashboard__dropdown {
  align-self: center;
  border-color: rgba(58,68,86, .2);
  font-size: .8vw;
  margin: 0 2% 0 2%;

  width: 10vw;
  height: 2vw;

  &:focus {
    outline: 0;
  }
}

.dashboard__export-pdf-button {
  align-self: center;
  background-color: #49C0AD;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-family: 'Open Sans';
  padding: 0;
  width: 6vw;
  height: 1.5vw;
  margin-right: 1.5%;

  &:hover {
    background-color: #009F93;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    background-color: #758299;
    cursor: default;
    color: rgba(255, 255, 255, .8);
  }
}

.dashboard__graphs-grid-1 {
  display: grid;
  margin-top: 2%;
  grid-gap: 1vw;
  grid-template-columns: 44vw 44vw;
  grid-template-rows: 15vw;
  @media print {
    grid-template-rows: 30vw;
  }
}

.dashboard__graphs-grid-2 {
  display: grid;
  margin-top: 1%;
  grid-gap: 1%;
  grid-template-columns: 89vw;
  grid-template-rows: 20vw;
}

.dashboard__graphs-grid-3 {
  display: grid;
  margin: 1% 0 1% 0;
  grid-gap: 1vw;
  grid-template-columns: 29vw 29vw 29vw;
  grid-template-rows: 15vw;
}

.dashboard__row-1 {
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}

.dashboard__row-2 {
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  margin-top: 1%;
}

.dashboard_row-1-item-2 {
  flex-direction: row;
  margin-top:30px;
  display: flex;
}
.dashboard__row-1-item {
  display: flex;
}

.MuiTypography-body1-38 {
  font-family: 'Open Sans' !important;
}

.dashboard__notifications-flex-1 {
  display: flex;
  align-items: center;
}

.dashboard_notifications_number-font {
 color:white !important
}

.notifications-button {
  position: absolute;
  top: 10px;
  right: 100px;
  margin-right: 1vw !important;
}

.notifications-statistic {
  position: absolute;
  top: .02vw;
  left:52vw;
  margin-right: 1vw !important;
}

.dashboard__customer-attribute-container {
  width: auto;
  min-width: 9vw;
  margin-right: 2vw;
}

.dashboard__customer-attribute-header {
  opacity: 0.7;
  margin: 0 !important;
  white-space: nowrap;
}

.dashboard__customer-attribute-label {
  margin: 0 !important;
  white-space: nowrap;
  font-size: 1.5rem;
  opacity: 1;
}

.dashboard__message-container {
  background-color: white;
  margin-top: 2%;
  width: 100%;
  height: 65vh;
  text-align: center;

  h1 {
    line-height: 65vh;
  }
}