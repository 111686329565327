// html { overflow-y: scroll; }

body {
  background-color: #EEF0F6;
  color: #3A4456;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans';

  // hide scroll bar
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar { width: 0 !important }
}


span {
  font-size: 1em !important;
}

$primary-purple: #9944BB;
$lightest-purple: #D2B2DD;
$medium-purple: #BB84D0;
$dark-purple: #441155;

$primary-green: #009F93;
$lightest-green: #49C0AD;
$medium-green: #016E75;
$dark-green: #03312E;

$primary-blue: #4BCBF3;
$lightest-blue: #8CDDF7;
$medium-blue: #0098C6;
$dark-blue: #094074;

$primary-dark-blue: #045C84;
$lightest-dark-blue: #DAE3EB;
$medium-dark-blue: #8DA9C4;
$dark-dark-blue: #0B2545;

$primary-blue-steel: #D2D6E8;
$lightest-blue-steel: #EEF0F6;
$medium-blue-steel: #758299;
$dark-blue-steel: #3A4456;

