.vitals-line-chart__container {
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 1.5vw 0% 1.5vw 0%;

  tspan {
    opacity: 0.7;
    font-size: .7vw;
  }

  h2 {
    margin-bottom: 1%;
  }
}

.vitals-line-chart__tooltip-value-container {
  align-items: center;
  display: flex;
}

.vitals-line-chart__tooltip {
  background-color: #3A4456;
  color: #EEF0F6;
  padding: 5%;
  font-size: .7vw;
  width: 11vw;

  p {
    margin: 0;

  }
}

.vitals-line-chart__tooltip-value {
  font-size: .9vw;
}

.vitals-line-chart__tooltip-units {
  font-size: .7vw;
  opacity: 0.5;
  margin-left: 2% !important;
}

.vitals-line-chart__x-axis-secondary {
  opacity: 0.7;
  font-size: 0.7vw;
}