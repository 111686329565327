.nav-top {
  position: absolute;
  background-color: #3A4456;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
}

.nav-top__logo {
  height: 50px;
  margin-left: 1%;
  width: auto;
}

.nav-top__sign-out {
  color: #EEF0F6;
  margin-right: 1%;
  text-decoration: none !important;
  width: 80px;
}

.nav-top__sign-out:hover {
  color: #49C0AD;
}

.header__customer-info {
    position: absolute;
  background-color: #D2D6E8;
  display: flex;
  align-items: center;
  top: 50px;
  height: 75px;
  left: 0px;
  width: 100%;
  padding: 0 5%;
}