.DateRangePickerInput {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.DateRangePicker {
  width: 100% !important;
  z-index: 100;
}

.DateInput_input {
  text-align: center !important;
  padding: 10% 0 7% 0;
}

.DateRangePickerInput_arrow_svg {
  padding-bottom: 5%;
}

.CalendarDay__highlighted_calendar {
  background: #D2B2DD;
  border: 1px double rgba(187, 132, 208, 0.7);

  &:hover {
    background: #BB84D0;
    border: 1px double rgba(187, 132, 208, 0.7);
  }
}

.CalendarDay__selected_span {
  background: #49C0AD;
  border: 1px double rgba(0, 159, 147, 0.5);

  &:hover {
    background: rgba(73, 192, 173, 0.8);
    border: 1px double rgba(0, 159, 147, 0.5);
  }
}

.CalendarMonth_table {
  margin-top: 4%;
}

.DateInput_input__focused {
  border-bottom: 2px solid #49C0AD;
}

.vitals-date-picker__date-presets {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2%;
}

.vitals-date-picker__date-presets2 {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2%;
}