.pdf-viewer {

  width: 85%;
  height: 100%;
  z-index: 999;
}

.pdf-viewer__container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: rgba(58,68,86, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 998;
  padding: 2% 2% 2% 2%;
}
.pdf-exit-button {
  position: absolute;
  z-index:1000;
  top:-22vw;
  left:-2.50vw;
  transition-delay: 1s;
 }